import React from "react";

const ServicesSection = () => {
  const circles = [
    "#F58D2A",
    "#D84028",
    "#1B476C",
    "#00739A",
    "#00AEE0",
    "#87D4EE",
  ];

  const services = [
	{
	  title: "Морские перевозки",
	  description:
		"Мы обеспечиваем безопасную и эффективную доставку груза любыми типами судов, достигая максимальной точности и минимальных сроков.",
	  bgColor: "bg-[#D14124]",
	  bgOpacity: "bg-opacity-50",
	  bgImage: "/img/service_1.jpg",
	},
	{
	  title: "Автомобильные грузоперевозки",
	  description:
		"Наша компания предоставляет качественные услуги по автомобильной доставке грузов, гарантируя оперативность и надежность на каждом этапе.",
	  bgColor: "bg-[#154468]",
	  bgOpacity: "bg-opacity-50",
	  bgImage: "/img/service_2.jpg",
	},
	{
	  title: "Железнодорожные перевозки",
	  description:
		"Железнодорожные перевозки предлагают высокую эффективность и экономичность для транспортировки крупных объемов на дальние расстояния.",
	  bgColor: "bg-[#00aee0]",
	  bgOpacity: "bg-opacity-50",
	  bgImage: "/img/service_4.jpg",
	},
	{
	  title: "Авиа перевозки",
	  description:
		"Осуществляем доставку грузов по воздуху, обеспечивая высокую скорость и безопасность на международном уровне.",
	  bgColor: "bg-[#F68D2E]",
	  bgOpacity: "bg-opacity-50",
	  bgImage: "/img/service_4.jpg",
	},
	{
	  title: "Страхование",
	  description:
		"Предоставляем комплексные страховые услуги для защиты грузов от возможных рисков в процессе транспортировки.",
	  bgColor: "bg-[#87d4ee]",
	  bgOpacity: "bg-opacity-50",
	  bgImage: "/img/service_3.jpg",
	},
	{
	  title: "Брокерские и складские услуги",
	  description:
		"Важные составляющие логистики, которые обеспечивают эффективное управление грузами и их безопасное хранение.",
	  bgColor: "bg-[#87d4ee]",
	  bgOpacity: "bg-opacity-50",
	  bgImage: "/img/service_6.jpg",
	}
  ];  

  return (
    <section className="py-16 md:py-24 bg-black text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-8">
          Услуги
        </h2>

        <div className="flex justify-center space-x-4 mb-12">
          {circles.map((color, index) => (
            <div
              key={index}
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: color }}
            ></div>
          ))}
        </div>

        <p className="text-center text-lg mb-12 max-w-3xl mx-auto">
          "REAL CARGO INDUSTRY" SRL специализируется на оперативной доставке
          грузов, обеспечивая клиентов быстрыми и надежными логистическими
          решениями.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className={`relative overflow-hidden rounded-lg shadow-lg flex flex-col`}
            >
              <div
                className="absolute inset-0 bg-cover bg-center z-0"
                style={{
                  backgroundImage: `url(${service.bgImage})`,
                  opacity: 0.2,
                }}
              ></div>
              <div
                className={`${service.bgColor} ${service.bgOpacity} absolute inset-0 z-10`}
              ></div>
              <div className="relative z-20 p-6 flex flex-col h-full">
                <h3 className="text-2xl font-semibold mb-4 text-white">
                  {service.title}
                </h3>
                <p className="text-white text-left">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
