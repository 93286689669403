import React, { useState, useEffect, useCallback } from 'react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const slides = [
	{
	  video: '/videos/air_transport.webm',
	  subtext1: 'Авиа перевозки',
	  subtext2: 'Обеспечиваем быструю и безопасную доставку грузов по воздуху, гарантируя высокие стандарты обслуживания и профессионализм.'
	},
	{
	  video: '/videos/insurance.webm',
	  subtext1: 'Страхование',
	  subtext2: 'Предоставляем комплексные страховые услуги, защищая грузы от рисков на каждом этапе транспортировки.'
	},
	{
	  video: '/videos/rail_transport.webm',
	  subtext1: 'Железнодорожные перевозки',
	  subtext2: 'Оптимизируем международные железнодорожные маршруты для ускорения доставки и обеспечения надежной логистики.'
	},
	{
	  video: '/videos/road_transport.webm',
	  subtext1: 'Автомобильные грузоперевозки',
	  subtext2: 'Предлагаем оперативные автомобильные перевозки с высоким уровнем обслуживания и индивидуальным подходом к каждому клиенту.'
	},
	{
	  video: '/videos/sea_transport.webm',
	  subtext1: 'Морские перевозки',
	  subtext2: 'Организуем безопасные морские перевозки для различных типов грузов, минимизируя сроки и гарантируя надежность.'
	}
  ];  

  const nextSlide = useCallback(() => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentSlide((prev) => (prev + 1) % slides.length);
    setTimeout(() => setIsTransitioning(false), 500);
  }, [isTransitioning, slides.length]);

  const prevSlide = useCallback(() => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    setTimeout(() => setIsTransitioning(false), 500);
  }, [isTransitioning, slides.length]);

  const goToSlide = useCallback((index: number) => {
    if (isTransitioning || index === currentSlide) return;
    setIsTransitioning(true);
    setCurrentSlide(index);
    setTimeout(() => setIsTransitioning(false), 500);
  }, [isTransitioning, currentSlide]);

  useEffect(() => {
    const timer = setInterval(nextSlide, 5000);
    return () => clearInterval(timer);
  }, [nextSlide]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigationItems = [
    { id: 'about', href: '#about', label: 'О нас' },
    { id: 'why', href: '#why', label: 'Почему мы?' },
    { id: 'services', href: '#services', label: 'Услуги' },
    { id: 'stages', href: '#stages', label: 'Этапы' },
    { id: 'contacts', href: '#contacts', label: 'Контакты' }
  ];

  return (
    <header className="relative w-full h-screen overflow-hidden">
      <div className="absolute inset-0">
        <video
          key={slides[currentSlide].video}
          className="w-full h-full object-cover"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={slides[currentSlide].video} type="video/webm" />
        </video>
        <div className="absolute inset-0 bg-black/40" />
      </div>

      <nav className="absolute top-0 left-0 w-full p-4 z-20">
        <div className="container mx-auto flex justify-between items-center">
          <div className="text-white text-2xl font-bold">RCI</div>

          <div className="hidden md:flex space-x-6">
            {navigationItems.map((item) => (
              <a key={item.id} href={item.href} className="text-white hover:text-gray-300 transition-colors duration-300">
                {item.label}
              </a>
            ))}
          </div>

          <div className="hidden md:flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="size-6 text-white" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
            </svg>
            <a href="/login" className="text-white hover:text-gray-300 transition-colors duration-300">Вход</a>
          </div>

          <button onClick={toggleMenu} className="md:hidden text-white focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </nav>

      {isMenuOpen && (
        <div className="absolute top-16 left-0 w-full bg-black bg-opacity-90 md:hidden z-20">
          <div className="container mx-auto">
            <div className="flex flex-col items-center py-4">
              {navigationItems.map((item) => (
                <a key={item.id} href={item.href} className="text-white py-2 hover:text-gray-300 transition-colors duration-300">
                  {item.label}
                </a>
              ))}
              <a href="/login" className="text-white py-2 hover:text-gray-300 transition-colors duration-300 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
                </svg>
                Вход
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="absolute inset-0 flex flex-col items-center justify-center z-10">
        <div className="text-center text-white px-4 mx-16 md:mx-24">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
            "REAL CARGO INDUSTRY" SRL
          </h1>
          <p className="text-xl md:text-2xl lg:text-3xl mb-8">
            – ваш надежный партнер в сфере оперативной доставки грузов!
          </p>
          <div className="max-w-4xl mx-auto">
            <p className={`text-lg md:text-xl lg:text-2xl mb-4 transition-opacity duration-500 ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}>
              {slides[currentSlide].subtext1}
            </p>
            <p className={`text-lg md:text-xl lg:text-2xl mb-8 transition-opacity duration-500 ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}>
              {slides[currentSlide].subtext2}
            </p>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          <button className="px-6 py-3 rounded-md text-white font-semibold bg-gradient-to-r from-[#F58D2C] via-[#D84028] via-[#1B476C] via-[#00739A] via-[#00AEE0] to-[#87D4EE]">
            Заказать доставку
          </button>
          <div className="rounded-md p-0.5 bg-gradient-to-r from-[#F58D2C] via-[#D84028] via-[#1B476C] via-[#00739A] via-[#00AEE0] to-[#87D4EE]">
            <button className="px-6 py-3 rounded-md text-white font-semibold bg-black">
              Стать перевозчиком
            </button>
          </div>
        </div>
      </div>

      <button 
        onClick={prevSlide} 
        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black/50 p-2 rounded-full text-white hover:bg-black/70 transition-colors z-10"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      
      <button 
        onClick={nextSlide} 
        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black/50 p-2 rounded-full text-white hover:bg-black/70 transition-colors z-10"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>

      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex justify-center space-x-2 z-10">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-3 h-3 rounded-full transition-colors ${
              currentSlide === index ? 'bg-white' : 'bg-white/50'
            }`}
          />
        ))}
      </div>
    </header>
  );
};

export default Header;
