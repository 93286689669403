import React from 'react';

const GeographySection = () => {
  return (
    <section className="bg-black py-16 text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">География обслуживания</h2>
        
        <p className="text-lg md:text-xl text-center max-w-3xl mx-auto mb-12">
			REAL CARGO INDUSTRY" SRL охватывает [Европа, СНГ, Азия], обеспечивая полный спектр логистических услуг для клиентов в этих регионах.
        </p>
        
        <div className="mb-16">
          <img 
            src="/img/map.png" 
            alt="Map of service geography" 
            className="w-full h-auto object-cover"
          />
        </div>
        
        <div className="flex flex-col md:flex-row items-center md:items-start gap-8">
          <div className="w-full md:w-1/2">
            <img 
              src="/img/truck.png" 
              alt="REAL CARGO INDUSTRY" 
              className="w-full h-auto object-cover rounded-lg shadow-lg"
            />
          </div>
          
          <div className="w-full md:w-1/2 flex flex-col items-end text-right">
            <h3 className="text-2xl md:text-3xl font-bold mb-4">REAL CARGO INDUSTRY SRL</h3>
            <p className="text-base md:text-lg max-w-md">
              C гордостью обслуживает клиентов с 2020 года. Успех нашей компании складывается из одного основополагающего фактора- это профессионализм сотрудников , заслуживший доверие крупнейших национальных компаний
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GeographySection;
