import React, { useState, useCallback, useEffect } from 'react';

const ClientsPartnersSection = () => {
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [offset, setOffset] = useState(0);

  const circles = [
    '#F58D2A', '#D84028', '#1B476C', '#00739A', '#00AEE0', '#87D4EE'
  ];

  const logos = [
    '/img/logo/crafti.png',
    '/img/logo/gorgan.png',
    '/img/logo/nefis.png',
    '/img/logo/piramida.png',
    '/img/logo/vefasistem.png',
    '/img/logo/logo1.png',
    '/img/logo/logo2.png',
    '/img/logo/logo3.png',
    '/img/logo/logo4.png',
    '/img/logo/logo5.png',
    '/img/logo/logo6.png',
    '/img/logo/logo7.png',
    '/img/logo/logo8.png',
    '/img/logo/logo9.png',
    '/img/logo/logo10.png',
    '/img/logo/logo11.png',
    '/img/logo/logo12.png',
    '/img/logo/logo13.png'
  ];

  const cards = [
    {
      title: "Заказать перевозку",
      description: "Если у Вас есть груз, который необходимо перевезти, Вы на правильном пути! Оставьте заявку на сайте и Мы подберём для вас самые выгодные условия и надёжного перевозчика",
    },
    {
      title: "Стать партнером",
      description: "Если Вы в поисках грузов для перевозки, оставьте заявку с Вашими данными и Мы подберём для Вас выгодное предложение, на лучших условиях и своевременной оплате.",
    },
  ];

  const getItemsPerView = () => {
    if (typeof window === 'undefined') return 3;
    if (window.innerWidth < 768) return 1;
    return 3;
  };

  const [itemsPerView, setItemsPerView] = useState(getItemsPerView());

  useEffect(() => {
    const handleResize = () => {
      setItemsPerView(getItemsPerView());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextSlide = useCallback(() => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    
    if (currentLogoIndex >= logos.length - 1) {
      setOffset(prev => prev + logos.length);
      setCurrentLogoIndex(0);
    } else {
      setCurrentLogoIndex(prev => prev + 1);
    }
    
    setTimeout(() => setIsTransitioning(false), 500);
  }, [isTransitioning, logos.length, currentLogoIndex]);

  const prevSlide = useCallback(() => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    
    if (currentLogoIndex <= 0) {
      setOffset(prev => prev - logos.length);
      setCurrentLogoIndex(logos.length - 1);
    } else {
      setCurrentLogoIndex(prev => prev - 1);
    }
    
    setTimeout(() => setIsTransitioning(false), 500);
  }, [isTransitioning, logos.length, currentLogoIndex]);

  useEffect(() => {
    const timer = setInterval(nextSlide, 3000);
    return () => clearInterval(timer);
  }, [nextSlide]);

  const visibleLogos = [...logos, ...logos, ...logos, ...logos];

  return (
    <section className="bg-black text-white py-12 md:py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-8">Клиенты и Партнеры</h2>
        <div className="flex justify-center space-x-4 mb-8">
          {circles.map((color, index) => (
            <div
              key={index}
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: color }}
            ></div>
          ))}
        </div>
        <p className="text-center text-lg md:text-xl mb-8">
          Мы гордимся тем, что обслуживаем широкий спектр клиентов, включая:
        </p>

        <div className="relative max-w-6xl mx-auto mb-12">
          <div className="absolute left-0 top-0 bottom-0 w-16 bg-gradient-to-r from-black to-transparent z-10" />
          <div className="absolute right-0 top-0 bottom-0 w-16 bg-gradient-to-l from-black to-transparent z-10" />
          
          <button 
            onClick={prevSlide}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white/90 p-3 rounded-full text-black hover:bg-white transition-colors z-20 shadow-lg"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>

          <div className="overflow-hidden mx-16">
            <div 
              className="flex transition-transform duration-500 ease-in-out"
              style={{ 
                transform: `translateX(-${(currentLogoIndex + offset) * (100 / itemsPerView)}%)`,
                opacity: isTransitioning ? 0.7 : 1
              }}
            >
              {visibleLogos.map((logo, index) => (
                <div 
                  key={`${logo}-${index}`}
                  className={`flex-shrink-0 flex items-center justify-center ${
                    itemsPerView === 1 ? 'w-full' : itemsPerView === 3 ? 'w-1/3' : 'w-1/2'
                  }`}
                  style={{ height: '160px' }}
                >
                  <div className="w-48 h-48 flex items-center justify-center p-4">
                    <img 
                      src={logo} 
                      alt={`Client logo ${index + 1}`} 
                      className="w-full h-full object-contain transition-all duration-300 hover:scale-105 hover:opacity-80"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <button 
            onClick={nextSlide}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white/90 p-3 rounded-full text-black hover:bg-white transition-colors z-20 shadow-lg"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>

        <div className="flex flex-col md:flex-row justify-center items-stretch gap-8">
          {cards.map((card, index) => (
            <div key={index} className="bg-white rounded-xl shadow-xl p-6 md:p-8 flex flex-col justify-between w-full md:max-w-md">
              <div className='text-black'>
                <h3 className="text-2xl md:text-3xl font-bold mb-4 text-left">{card.title}</h3>
                <p className="text-base md:text-lg mb-6 text-left">{card.description}</p>
              </div>
              <div className="text-left">
                <button className="px-6 py-2 rounded-md text-white font-semibold bg-gradient-to-r from-[#F58D2A] via-[#D84028] via-[#1B476C] via-[#00739A] via-[#00AEE0] to-[#87D4EE] hover:opacity-90 transition-opacity">
                  Подробнее
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClientsPartnersSection;
